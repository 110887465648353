import Component from 'vue-class-component'
import isEmpty from 'lodash/isEmpty'
import forEach from 'lodash/forEach'
import includes from 'lodash/includes'
import { State } from 'vuex-class'
import find from 'lodash/find'

// Base Page
import BasePage from '@/pages/Base'

// Services
import AuthService from '@/services/Auth'
import ProfileService from '@/services/Profile'

@Component
export default class Login extends BasePage {
  constructor() {
    super()
  }

  // password: string = 'Password'
  // passwordField: string = 'Password'
  show1: boolean = false
  imageLogoSource: any = require('../../assets/img/logo-full.png')
  loadingLogin: boolean = false
  usernameField: string = ''
  passwordField: string = ''
  member: string = ''
  hasAdmin: boolean = false

  /* VUEX */
  @State('snackbar')
  snackbar

  async login() {
    this.loadingLogin = true
    try {
      const response = await AuthService.login(this.usernameField, this.passwordField)
      window.localStorage.setItem('access_token', response.data.access_token)

      const responseProfile = await ProfileService.getProfileData()

      console.log(responseProfile, 'responseProfile');

      // check if role has only one
      if(responseProfile.data.attributes.is_admin) {
        this.hasAdmin = true

        if (this.hasAdmin) {
          // code...
          this.member = 'Admin'
        }

        var roleGoIn = [
          'Admin','Super Admin','Administrator'
        ]

        // check if role same like in roleGoIn
        var mustLogIn = includes(roleGoIn, this.member)
        if(mustLogIn) {
          setTimeout(() => {
            this.$router.push('/')
          }, 450)
        } else {
          await AuthService.logout()
        }


      } else {
        await AuthService.logout()
      }



      // if(this.member !== "Admin") {
      //   await AuthService.logout()
      // }

      // setTimeout(() => {
      //   this.$router.push('/')
      // }, 450)
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.loadingLogin = false
    }
  }
}
