import BaseService from '@/services/BaseService'

class ProfileService extends BaseService {
  constructor() {
    super()
  }

  getProfileData() {
    // this.endPoint = 'api/me'
    this.endPoint = 'api/v2/me'
    return this.get().then(response => {
      return response.data
    })
  }

  // getLoginUser(id) {
  //   this.endPoint = 'api/users/'+id
  //   return this.get().then(response => {
  //     return response.data
  //   })
  // }  
}

export default new ProfileService()
